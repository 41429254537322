import React, { useContext, useState } from 'react'
import { Routes, Route, Link, useParams, useLocation } from "react-router-dom"

import './styles.css'
import GlobalContext from '../../state/GlobalContext';

export default function Banner() {
  const {state} = useContext(GlobalContext);
  const location = useLocation()
  console.log(state.pageTitle)
  return (
  
    <div className='banner'  style={location.pathname!=="/" ? { height:200, position: 'sticky'} : {}}>
      <div class="layer">
      {location.pathname==="/" ?<div class="textbox">
        <div className='bannerTitle'>{state.pageTitle.title}</div>
        <div className='seperator'></div>
        <div className='bannerSubTitle'>{state.pageTitle.subtitle}</div>
        </div> : <></>}        
      </div></div>   
  )
}
