import React, { useContext, useState } from 'react'
import { STRAPIURL } from '../../Constant';

import './styles.css'

export default function News({title, summary, date, img}) {    
  const date1 = new Date(date);  // 2009-11-10
const month = date1.toLocaleString('default', { month: 'long' });

let dateMDY = `${date1.getDate()}. ${month} ${date1.getFullYear()}`;


console.log(dateMDY);

    return <div className='NewsBlock'>
       {
                img &&  <div ><img className='NewsImg' src={STRAPIURL+img.url}/></div>
            }
     
      <div className='NewsDate'>{dateMDY}</div>
      <div className="NewsRuler"> </div>
      <div className="NewsTitle">{title}</div>
      <div className="NewsAbstract">{summary}</div>
    
    </div>  
}
