import { gql } from '@apollo/client';

export const GET_EMPLOYEE = gql`
  query GetUser($id: ID!) {
   employee  (id: $id) {data {id, attributes {Name, Tags, Bio, Email,  Title, Image{data{attributes{url}}}}}}
  }
`;

export const GET_EMPLOYEES = gql`
  query GetUsers {
   employees  (pagination: {limit:50}) {data {id, attributes {Name, Tags, Title, Image{data{attributes{url}}}}}}
  }
`;

export const GET_HOME = gql`
  query GetHomeCont {
   home{data{attributes{title, subtext}}}
  }
`;

export const GET_FEW_NEWS = gql`
  query GetNews {
    stories{
    data{attributes{title, summary, date, img{data{attributes{url}}}}}
    }  
  }
`;


