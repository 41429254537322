import React, { useEffect, useState } from 'react'
import  { Link } from 'react-router-dom'
import './styles.css'

import { BlocksRenderer, type BlocksContent } from '@strapi/blocks-react-renderer';

export default function EmployeeDetail({attributes}) {
    const [uri, seturi] = useState();
    console.log(attributes);
    useEffect
    (()=>{  
      if(attributes.Image.data) seturi("http://185.112.144.96:1337"+attributes.Image.data.attributes.url)
    },[attributes.Image]) 
  
    
  return (
  
      <div className='ed-empCard'>
        <div className='ed-imgBox'>{uri?<img  src={uri+"?w=200"}/>:<></> }</div>
        <div className='ed-empDetail'>
          <div className='ed-empName'>{attributes.Name}</div>
          <div  className='ed-emptitle'>{attributes.Title}</div>   
          <div className='ed-body'>
          {attributes.Bio?
            <BlocksRenderer content={attributes.Bio} />:<></>}
          </div>
        </div>      
      </div>
  
  )
}
