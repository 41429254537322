import React, { useEffect, useState } from 'react'
import  { Link } from 'react-router-dom'
import './styles.css'
import { STRAPIURL } from '../../Constant';

export default function Employee({attributes}) {
    const [uri, seturi] = useState();
    console.log(attributes);
    useEffect
    (()=>{  
      if(attributes.Image.data) seturi(STRAPIURL+attributes.Image.data.attributes.url)
    },[attributes.Image]) 
  
    
  return (
  
      <div className='empCard'>
        <div className='imgBox'>{uri?<img  src={uri+"?w=200"}/>:<></> }</div>
        <div className='empDetail'>
          <div className='empName'>{attributes.Name}</div>
          <div  className='emptitle'>{attributes.Title}</div>   
        </div>      
      </div>
  
  )
  //<Link to={"/employee/"+name.replaceAll(' ', '-')}>more... </Link>
}
