import './App.css';

import GetEmployees from "./Components/GetEmployees"
import ApolloAppProvider from './ApolloProvider';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import SiteHeader from "./Components/SiteHeader"
import Home from './Pages/Home';
import Employees from './Pages/Employees';
import Employee from './Pages/Employee';
import About from './Pages/About';
import Bottom from './Components/Footer';
import Banner from './Components/Banner';

function App() {
  console.log('I was triggered during render')
  return ( 
    <div className="App"><ApolloAppProvider><Router>
      <div>
        <SiteHeader/>
        <div className="background">
          <Banner/> 
          <div className="container">
            <Routes>
              <Route path="/" element={<Home />} />       
              <Route path="/Employees" element={<Employees />}/>
              <Route path="/Employee/:id/:name" element={<Employee />}/>
              <Route path="/About" element={<About />}/>
              <Route path="/About/:id" element={<About />}/>
            </Routes>
          </div>
      </div>
    </div><Bottom></Bottom></Router></ApolloAppProvider></div> );
}

export default App;
