import React, {useContext, useEffect} from 'react'
import {GET_HOME } from "../GraphQL/Queries";
import News from "../Components/News";
import {GET_FEW_NEWS } from "../GraphQL/Queries";
import { normalize } from '../GraphQL/Normalize';

import GlobalContext from '../state/GlobalContext';

import {  useQuery } from '@apollo/client';

export default function Home() {
  const {state, dispatch} = useContext(GlobalContext);
  const { loading, error, data } = useQuery(GET_HOME);
  const NewsData = useQuery(GET_FEW_NEWS);

  useEffect(() => {
    if(data)
    dispatch({type: "ADD_PAGE_TITLE", payload: {title:data.home.data.attributes.title, subtitle:data.home.data.attributes.subtext}});
}, [data]);
  
console.log(News)

  if(!data) return <div>loading</div>

  if (NewsData.data) {
      console.log(NewsData.data.stories.data);
      const datanorm=normalize(NewsData.data.stories);
      console.log(datanorm)
;
return <>       
        <div className='flex-container'>{datanorm?datanorm.map((val)=>{
          console.log(val.Title);
          
        return <div >
          <News {...val}></News>
          </div>
    }):<></>}</div></>
}


   
 }
