import React, { useState } from "react";
import { Link } from "react-router-dom";
import { IoMenuSharp } from "react-icons/io5";
import logo from "../../images/eflingLogo.png";
import { menusItems } from "../../Constant";
import "./styles.css";

export default function SiteHeader() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleMenu = () => {
    setIsOpen((open) => !open);
  };
  return (
    <header
      className="fixed 
    top-0 left-0 flex justify-end md:justify-around z-50 w-full"
    >
      <div className="absolute w-full md:w-60 h-20 md:h-32 left-0 md:left-24 bg-ef-blue flex md:align-middle md:justify-center  md:rounded-b-3xl">
        <a href="#" className="absolute top-1">
          {" "}
          <img src={logo} className="w-28 md:w-40 ml-2 md:ml-0" alt="logo" />
        </a>
      </div>
      <div className="absolute justify-center align-middle left-1/3 w-2/4 h-16 flex  bg-ef-header rounded-full top-7 hidden md:block">
        <ul className="absolute w-full flex top-3 justify-evenly">
        {menusItems.map(({ title, url }) => {
          return (
            <li key={title} className="no-underline   p-2 px-4 text-white hover:bg-gray-50 hover:text-black text-base rounded-full ">              
              <Link to={url}>{title}</Link>             
            </li>
          )
        })}
        </ul>
      </div>
      <IoMenuSharp
        className="w-16 top-2 relative h-16 self-end fill-ef-yellow md:hidden "
        onClick={toggleMenu}
      />
      <div className={`bg-white md:hidden ${isOpen && 'hidden'} absolute top-20 w-full justify-end`} >
        <ul className=" flex-row right-0 w-full border-2">
        {menusItems.map(({ title, url }) => {
          return (
            <li className="relative right-0" key={title}>              
              <Link onClick={toggleMenu} to={url}>{title}</Link>             
            </li>
          )
        })}
        </ul>
      </div>
    </header>
  );
}
